<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub">
            <h5 class="text-primary mb-2">현재 리워드 자산</h5>
            <h1>158,765 P</h1>
        </div>
        <!-- <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-pink">
            <h5 class="text-pink mb-2">금일 리워드 자산 등록</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-blue">
            <h5 class="text-blue mb-2">현재 리워드 자산</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-gray-100">
            <h5 class="text-gray mb-2">현재 리워드 자산</h5>
            <h1>158,765 P</h1>
        </div> -->
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 리워드 자산 등록</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금일 리워드 지급</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금월 누적 리워드 지급</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-pink">
           <h5 class="text-pink mb-2">현 잔고 자산</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="py-3 px-4 mx-3 rounded-4 mb-3 bg-green">
            <h5 class="text-green mb-2">금년 누적 리워드 지급</h5>
            <h1>158,765 P</h1>
        </div>
        <div class="bottom_box position-fixed bottom-0 start-0 w-100 p-3 bg-white">
            <div class="flex-between-center gap-2">
                <router-link to="/" class="w-100 py-2 px-3 btn bg-gray-200">상품권 교환</router-link>
                <router-link to="/my-assets/user-list" class="w-100 py-2 px-3 btn bg-gray-200">리워드 내역</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산'
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>