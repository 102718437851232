var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('TitleHeader',{attrs:{"title":_vm.title}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"bottom_box position-fixed bottom-0 start-0 w-100 p-3 bg-white"},[_c('div',{staticClass:"flex-between-center gap-2 mb-2"},[_c('router-link',{staticClass:"w-100 py-2 px-3 btn bg-gray-200",attrs:{"to":"/my-assets/smc-list"}},[_vm._v("My 유저")]),_c('router-link',{staticClass:"w-100 py-2 px-3 btn bg-gray-200",attrs:{"to":"/my-assets/smc-charge"}},[_vm._v("자산 충전소")])],1),_c('div',{staticClass:"flex-between-center gap-2"},[_c('router-link',{staticClass:"w-100 py-2 px-3 btn bg-gray-200",attrs:{"to":"/"}},[_vm._v("수수료지급신청")]),_c('router-link',{staticClass:"w-100 py-2 px-3 btn bg-gray-200",attrs:{"to":"/"}},[_vm._v("쇼핑몰 이동")])],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4 px-4 mx-3 rounded-4 mb-3 bg-sub"},[_c('h5',{staticClass:"text-primary mb-2"},[_vm._v("현재 충전 자산")]),_c('h1',[_vm._v("158,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금일 리워드 자산 등록")]),_c('h1',[_vm._v("158,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금일 유저관리수수료 지급")]),_c('h1',[_vm._v("158,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금월 누적 유저관리수수료 지급")]),_c('h1',[_vm._v("158,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-pink"},[_c('h5',{staticClass:"text-pink mb-2"},[_vm._v("현 잔고 자산")]),_c('h1',[_vm._v("158,765 P")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 px-4 mx-3 rounded-4 mb-3 bg-green"},[_c('h5',{staticClass:"text-green mb-2"},[_vm._v("금년 누적 유저관리수수료 지급")]),_c('h1',[_vm._v("158,765 P")])])
}]

export { render, staticRenderFns }