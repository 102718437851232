<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub text-center">
            <h5 class="text-primary">금일 리워드 자산편입 내역<br>
            <span class="fs-px-16 text-body">노래 6곡, 광고 1곡 리워드 자산등록</span></h5>
        </div>

        <div class="fs-px-13 px-3">
            <div class="row">
                <div class="col-2">순서</div>
                <div class="col-4">미션</div>
                <div class="col-2">음성<br>인식</div>
                <div class="col-2">점수</div>
                <div class="col-2">자산<br>등록</div>
            </div>
            <div class="row" v-for="(item, index) in list" :key="index" :class="{'bg-pink':!item.success}">
                <div class="col-2">{{index+1}}</div>
                <div class="col-4">{{item.title}}</div>
                <div class="col-2">{{item.rate}}</div>
                <div class="col-2" v-if="item.type==='song'">{{item.score}}</div>
                <div class="col-2" v-else>{{item.score===100?'정답':'오답'}}</div>
                <div class="col-2">{{item.success?'성공':'실패'}}</div>
                
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산',
            list:[
                {
                    type:'song',
                    title:'그런 사람 또 없습니다',
                    rate:95,
                    score:90,
                    success:true
                },
                {
                    type:'song',
                    title:'사랑은 늘 도망가',
                    rate:85,
                    score:90,
                    success:true
                },
                {
                    type:'song',
                    title:'광화문 연가',
                    rate:65,
                    score:65,
                    success:false
                },
                {
                    type:'song',
                    title:'좋니',
                    rate:95,
                    score:90,
                    success:true
                },
                {
                    type:'song',
                    title:'DNA',
                    rate:95,
                    score:90,
                    success:true
                },
                {
                    type:'song',
                    title:'꼬마인형',
                    rate:55,
                    score:30,
                    success:false
                },
                {
                    type:'ad',
                    title:'광동제약',
                    score:100,
                    success:true
                },
                {
                    type:'ad',
                    title:'삼성전자',
                    score:0,
                    success:false
                }
            ]
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>