<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub">
            일반적인 결제시스템 구현
<br><br>
- 신용카드<br>
    - Pay<br>
    - 계좌이체<br>
    - 핸드폰 <br>
      소액결제

        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산'
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 124px;
    }
</style>