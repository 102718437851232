<template>
    <div class="page">
        <TitleHeader :title="title" />
        <div class="py-4 px-4 mx-3 rounded-4 mb-3 bg-sub text-center">
            <h5 class="text-primary mb-2">관리유저/미션수행평가<br>
            <span class="fs-px-16 text-body">노래 265곡, 기타 485건 리워드 자산등록</span></h5>
        </div>

        <div class="fs-px-13 px-3">
            <div class="row">
                <div class="col-2">순번</div>
                <div class="col-2">id</div>
                <div class="col-6 d-flex flex-column text-center">
                    <span>금일 미션평가</span>
                    <div class="d-flex">
                        <div class="w-50">노래</div>
                        <div class="w-50">기타<br>미션</div>
                    </div>
                </div>
                <div class="col-2">연락처</div>
            </div>
            <div class="row mb-2" v-for="(item, index) in list" :key="index">
                <div class="col-2">{{index+1}}</div>
                <div class="col-2">{{item.id}}</div>
                <div class="col-6"><div class="d-flex text-center">
                    <div class="w-50" :class="{'bg-pink':item.song<20}">{{item.song}}</div>
                    <div class="w-50" :class="{'bg-pink':item.mission<30}">{{item.mission}}</div>
                </div></div>
                <div class="col-2 p-0"><button class="btn btn-sm border">연락</button></div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleHeader from '@/components/common/TitleHeader.vue'

export default {
    components:{
        TitleHeader,
    },
    data(){
        return{
            title: 'My 자산',
            list:[
                {
                    type:'song',
                    id:'smadkl',
                    song:15,
                    mission:90,
                    contact:{
                        type:'call',
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:85,
                    mission:90,
                    contact:{
                        type:'kakaotalk',
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:18,
                    mission:65,
                    contact:{
                        type:'line',
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:95,
                    mission:22,
                    contact:{
                        type:'instagram',
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:95,
                    mission:90,
                    contact:{
                        type:'facebook',
                    }
                },
                {
                    type:'song',
                    id:'smadkl',
                    song:55,
                    mission:30,
                    contact:{
                        type:'linkedin',
                    }
                },
                {
                    type:'ad',
                    id:'smadkl',
                    mission:100,
                    contact:true
                },
                {
                    type:'ad',
                    id:'smadkl',
                    mission:0,
                    contact:false
                }
            ]
        }
    }

}
</script>

<style scoped>
    .page{
        padding-bottom: 75px;
    }
</style>